import { Button, Flex, Grid, Message, MessageText, Modal, Text } from '@pancakeswap/uikit'
import { useLocalNetworkChain } from 'hooks/useActiveChainId'
import { useTranslation } from '@pancakeswap/localization'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import Image from 'next/image'
import useAuth from 'hooks/useAuth'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { useRouter } from 'next/router'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useAccount, useNetwork } from 'wagmi'
import { useMemo } from 'react'
import { ChainId } from '@pancakeswap/sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import Dots from '../Loader/Dots'
import { NWPolygon, WarningIconYellow } from '../../../packages/uikit/src/components/Svg'

// Where chain is not supported or page not supported
export function UnsupportedNetworkModal() {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { chains } = useNetwork()
  const chainId = useLocalNetworkChain() || ChainId.FANTOM
  const { chainId: _chain } = useActiveWeb3React()

  const { isConnected } = useAccount()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const menuItems = useMenuItems()
  const { pathname } = useRouter()

  const title = useMemo(() => {
    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

    return activeSubMenuItem?.label || activeMenuItem?.label
  }, [menuItems, pathname])

  const supportedMainnetChains = useMemo(() => chains.filter((chain) => !chain.testnet), [chains])

  return (
    <Modal bodyPadding="16px 24px 24px" className="modal-unsp-network" title={t('Check your network')} hideCloseButton>
      <Grid style={{ gap: '24px' }} maxWidth="336px">
        <Text>
          {t('Prediction only supported in', { feature: typeof title === 'string' ? title : 'this page' })}{' '}
          {supportedMainnetChains?.map((c) => c.name).join(', ')}
        </Text>
        <div style={{ textAlign: 'center' }}>
          <NWPolygon />
        </div>
        <Flex
          flexDirection="column"
          alignItems="stretch"
          style={{
            gap: 16,
          }}
        >
          <div className="wrapper-warning">
            <WarningIconYellow />
            <MessageText>{t('Please switch your network to continue.')}</MessageText>
          </div>
          {canSwitch && (
            <Button
              isLoading={isLoading}
              onClick={() => {
                if (supportedMainnetChains.map((c) => c.id).includes(chainId)) {
                  switchNetworkAsync(chainId)
                } else {
                  switchNetworkAsync(supportedMainnetChains[0].id)
                }
              }}
            >
              {isLoading ? <Dots>{t('Switch network in wallet')}</Dots> : t('Switch network in wallet')}
            </Button>
          )}
          {isConnected && (
            <Button variant="secondary" onClick={logout}>
              {t('Disconnect Wallet')}
            </Button>
          )}
        </Flex>
      </Grid>
    </Modal>
  )
}
