import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  ",
        "\n\n  &.box-already-login {\n    align-items: center;\n    border: 1px solid hsla(0, 0%, 100%, 0.102);\n    border-radius: 0.8rem;\n    border-radius: 8px;\n    color: #fff;\n    display: inline-flex;\n    height: 40px;\n    padding: 0 6px;\n    position: relative;\n\n    div {\n      background: none;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
var Flex = styled(Box).withConfig({
    componentId: "sc-443e9930-0"
})(_templateObject(), flexbox);
export default Flex;
