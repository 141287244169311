import { mainnet, goerli, optimism, optimismGoerli } from 'wagmi/chains'
import { Chain } from 'wagmi'

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/avalanche'],
    },
    public: {
      http: ['https://rpc.ankr.com/avalanche'],
    },
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
}

export const avalandcheFuji: Chain = {
  id: 43113,
  name: 'Avalanche Fuji',
  network: 'avalanche-fuji',
  // rpcUrls: {
  //   default: 'https://rpc.ankr.com/avalanche_fuji',
  // },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/avalanche_fuji'],
    },
    public: {
      http: ['https://rpc.ankr.com/avalanche_fuji'],
    },
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://testnet.snowtrace.io/',
    },
  },
  testnet: true,
}

export const polygonMainnet: Chain = {
  id: 137,
  name: 'Polygon Mainnet',
  network: 'polygon',
  nativeCurrency: { name: 'Polygon', symbol: 'MATIC', decimals: 18 },
  // rpcUrls: {
  //   default: 'https://rpc-mainnet.maticvigil.com',
  // },
  rpcUrls: {
    default: {
      http: ['https://rpc-mainnet.maticvigil.com'],
    },
    public: {
      http: ['https://rpc-mainnet.maticvigil.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'PolygonScan',
      url: 'https://polygonscan.com',
    },
  },
}

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.ftm.tools'],
    },
    public: {
      http: ['https://rpc.ftm.tools'],
    },
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
}

export const fantomTestnet: Chain = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'fantom-testnet',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet.fantom.network'],
    },
    public: {
      http: ['https://rpc.testnet.fantom.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://testnet.ftmscan.com',
    },
  },
  testnet: true,
}

export const opMainnet: Chain = {
  id: 10,
  name: 'Optimism Mainnet',
  network: 'Optimism',
  nativeCurrency: { name: 'Optimism', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/optimism/'],
    },
    public: {
      http: ['https://rpc.ankr.com/optimism/'],
    },
  },
  blockExplorers: {
    default: {
      name: 'OpScan',
      url: 'https://optimistic.etherscan.io/',
    },
  },
}

export const fantom: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'Fantom',
  nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.ftm.tools'],
    },
    public: {
      http: ['https://rpc.ftm.tools'],
    },
  },
  blockExplorers: {
    default: {
      name: 'FtmScan',
      url: 'https://ftmscan.com/',
    },
  },
}

const bscExplorer = { name: 'BscScan', url: 'https://optimistic.etherscan.io/' }

export const bsc: Chain = {
  id: 10,
  name: 'Optimism Mainnet',
  network: 'Optimism',
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/optimism/'],
    },
    public: {
      http: ['https://rpc.ankr.com/optimism/'],
    },
  },

  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Optimism Chain Native Token',
    symbol: 'ETH',
    decimals: 18,
  },
  // multicall: {
  //   address: '0xcA11bde05977b3631167028862bE2a173976CA11',
  //   blockCreated: 15921452,
  // },
}

// const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }

// export const bsc: Chain = {
//   id: 56,
//   name: 'BNB Smart Chain',
//   network: 'bsc',
//   rpcUrls: {
//     public: 'https://bsc-dataseed1.binance.org',
//     default: 'https://bsc-dataseed1.binance.org',
//   },
//   blockExplorers: {
//     default: bscExplorer,
//     etherscan: bscExplorer,
//   },
//   nativeCurrency: {
//     name: 'Binance Chain Native Token',
//     symbol: 'BNB',
//     decimals: 18,
//   },
//   multicall: {
//     address: '0xcA11bde05977b3631167028862bE2a173976CA11',
//     blockCreated: 15921452,
//   },
// }

export const bscTest: Chain = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  // rpcUrls: {
  //   public: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
  //   default: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
  // },
  rpcUrls: {
    default: {
      http: ['https://data-seed-prebsc-1-s2.binance.org:8545/'],
    },
    public: {
      http: ['https://data-seed-prebsc-1-s2.binance.org:8545/'],
    },
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  // multicall: {
  //   address: '0xcA11bde05977b3631167028862bE2a173976CA11',
  //   blockCreated: 17422483,
  // },
  testnet: true,
}

export { mainnet, goerli, optimism, optimismGoerli }
