import { ToastFailedIcon, ToastInfoIcon, ToastSuccessIcon, ToastWarnIcon } from '@pancakeswap/uikit'
import React from 'react'
import { toast, ToastContent, ToastOptions } from 'react-toastify'

interface Options {
  hideTitle?: boolean
  opts?: ToastOptions
}

export const helperToast = {
  success: (content: ToastContent, options?: Options) => {
    const { hideTitle = false, opts } = options || {}
    toast.success(() => {
      return (
        <div className="toastify-container">
          <ToastSuccessIcon className="status-icon-wrapper success" />
          <div className="toastify-group">
            {!hideTitle && <span>Success</span>}
            <div className="toastify-content-wrapper">{content as React.ReactNode}</div>
          </div>
        </div>
      )
    }, opts)
  },
  error: (content: ToastContent, options?: Options) => {
    const { hideTitle = false, opts } = options || {}
    toast.dismiss()
    toast.error(
      <div className="toastify-container">
        <ToastFailedIcon className="status-icon-wrapper error" />
        <div className="toastify-group">
          {!hideTitle && <span>Error</span>}
          <div className="toastify-content-wrapper">{content as React.ReactNode}</div>
        </div>
      </div>,
      opts,
    )
  },
  warn: (content: ToastContent, options?: Options) => {
    const { hideTitle = false, opts } = options || {}
    toast.dismiss()
    toast.warn(
      <div className="toastify-container">
        <ToastWarnIcon className="status-icon-wrapper warn" />
        <div className="toastify-group">
          {!hideTitle && <span>Warning</span>}
          <div className="toastify-content-wrapper">{content as React.ReactNode}</div>
        </div>
      </div>,
      opts,
    )
  },
  info: (content: ToastContent, options?: Options) => {
    const { hideTitle = false, opts } = options || {}
    toast.dismiss()
    toast.info(
      <div className="toastify-container">
        <ToastInfoIcon className="status-icon-wrapper info" />
        <div className="toastify-group">
          {!hideTitle && <span>Info</span>}
          <div className="toastify-content-wrapper">{content as React.ReactNode}</div>
        </div>
      </div>,
      opts,
    )
  },
}
