import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .txt-red{
    color:#FF4D4F !important;
  }

  
  .Toastify__toast--error {
    background: #333957;
    color: white;
    border-radius: 0.6rem;
  }

  .Toastify__toast--success {
    background: transparent linear-gradient(90deg, #19836c 0%, #177c66 100%) 0% 0% no-repeat padding-box;
    color: white;
    box-shadow: 0px 0.3rem 0.6rem #0000005c;
    border-radius: 0.4rem;
  }

  .Toastify__close-button {
    align-self: flex-start;
    border-radius: 0.4rem;
    width: 2.1rem;
    min-width: 2.1rem;
    height: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: none;
  }

  .Toastify__close-button:hover {
    background: #ffffff47 0% 0% no-repeat padding-box;
  }

  .Toastify__toast-body {
    font: normal normal normal 1.4rem/1.6rem Relative;
    line-height: 2rem;
  }


.Toastify__toast-container {
  z-index: 9999999999;
  width: 308px !important;
}

@media screen and (max-width: 767px) {
  .Toastify__toast-container {
    width: 100% !important;
  }
}

.Toastify__toast {
  padding: 16px;
  border-radius: 12px;
}

.Toastify__toast-body {
  align-items: start;
  padding: 0 !important;
}

.Toastify__close-button {
  path {
    fill: rgba(255, 255, 255, 0.6);
  }
}

.Toastify__toast--info {
  background: linear-gradient(90deg, #0a2462 0%, #010610 100%);
}

.Toastify__toast--success {
  background: linear-gradient(90deg, #043e30 0%, #010d0a 100%);
}

.Toastify__toast--warning {
  background: linear-gradient(90deg, #3e2e04 0%, #120d01 100%);
}

.Toastify__toast--error {
  background: linear-gradient(90deg, #340906 0%, #0e0202 100%);
}

.Toastify__progress-bar--info {
  background: #366ae2;
}

.Toastify__progress-bar--success {
  background: #03f5ae;
}

.Toastify__progress-bar--warning {
  background: #ffb200;
}

.Toastify__progress-bar--error {
  background: #f6475d;
}

.toastify-container {
  display: flex;
  align-items: start;
  gap: 8px;

  .toastify-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  span {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .status-icon-wrapper {
    width: 20px;
    height: 20px;
    padding: 0 !important;

    &.success {
      path {
        fill: #03f5ae;
      }
    }

    &.error {
      path {
        fill: #f6475d;
      }
    }

    &.info {
      path {
        fill: #366ae2;
      }
    }

    &.warn {
      path {
        fill: #ffb200;
      }
    }
  }

  .link-underline {
    text-decoration: none !important;
  }

  .toastify-content-wrapper {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;

    a {
      color: #03f5ae;

      span {
        font-weight: 400 !important;
        color: #03f5ae;
      }

      &:hover {
        color: #67f3c9;

        span {
          color: #67f3c9;
        }
      }
    }

    .connect-error-wrapper {
      .connect-error-content {
        color: rgba(255, 255, 255, 0.6);
      }

      .connect-error-switch {
        color: #03f5ae;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:hover {
          cursor: pointer;
          color: #67f3c9;
        }
      }
    }
  }
}
`

export default GlobalStyle
