import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  AnalyticsIcon,
  PresaleIcon,
  BridgesIcon,
  ScreenerIcon,
  AboutIcon,
  MysteryBoxIcon,
  YourNFTIcon,
  StakeIcon,
  VoteMenuIcon,
  LiquidityIcon,
  PioneerIcon,
  PredictionIcon,
  ReferralsIcon,
  LeaderboardIcon,
  PerpIcon,
  SpotNativeIcon,
  SpotThroughIcon,
  AnalyticsV2Icon,
  AnalyticsV1Icon,
  NewsIcon,
  SkeletonIcon,
  LayerZeroIcon,
  WormholeIcon,
  PoLIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Dashboard'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: 'https://app.mummy.finance/#/dashboard',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Perp'),
      href: 'https://app.mummy.finance/#/trade',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Swap'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: 'https://app.mummy.finance/#/swap',
      showItemsOnMobile: false,
      items: [
        // {
        //   label: t('Spot Native'),
        //   href: 'https://app.mummy.finance/#/spot-native',
        //   icon: SpotNativeIcon,
        //   describe: 'Swap tokens in an instant',
        // },
        // {
        //   label: t('Spot Through Aggregator'),
        //   href: 'https://app.mummy.finance/#/spot-aggregator',
        //   icon: SpotThroughIcon,
        //   describe: 'Swap tokens using the best route powered by KyberSwap',
        // },
      ],
    },
    {
      label: t('Earn'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: 'https://app.mummy.finance/#/earn-v2/',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Vaults'),
      href: 'https://app.mummy.finance/#/vault',
      showItemsOnMobile: true,
      items: [],
    },
    {
      label: t('Buy'),
      href: 'https://app.mummy.finance/#/buy',
      showItemsOnMobile: true,
      items: [],
    },
    {
      label: t('Prediction (BETA)'),
      href: 'https://prediction.mummy.finance/',
      showItemsOnMobile: true,
      items: [],
    },
    {
      label: t('NFT Club'),
      href: 'https://app.mummy.finance/#/portfolio',
      showItemsOnMobile: true,
      items: [],
    },
    // {
    //   label: t('Portfolio'),
    //   href: 'https://app.mummy.finance/#/nft',
    //   showItemsOnMobile: true,
    //   items: [],
    // },
    // {
    //   label: t('Bridge'),
    //   href: 'https://app.mummy.finance/#/portfolio',
    //   showItemsOnMobile: true,
    //   items: [
    //     {
    //       label: t('LayerZero'),
    //       href: 'https://app.mummy.finance/#/bridge',
    //       icon: LayerZeroIcon,
    //       describe: 'Bridge MMY seamlessly via Layer0 technology',
    //     },
    //     {
    //       label: t('Wormhole'),
    //       href: 'https://app.mummy.finance/#/bridge-wormhole',
    //       icon: WormholeIcon,
    //       describe: 'Bridge tokens seamlessly via Wormhole technology',
    //     },
    //   ],
    // },

    // {
    //   label: t('Analytics'),
    //   icon: AnalyticsV2Icon,
    //   fillIcon: SwapFillIcon,
    //   href: 'https://app.mummy.finance/#/analytic/overview',
    //   showItemsOnMobile: false,
    //   items: [
    //     {
    //       label: t('Analytics V1'),
    //       href: 'https://app.mummy.finance/#/analytic/overview',
    //       icon: AnalyticsV1Icon,
    //       describe: 'The comprehensive statistics and data visualization of V1',
    //     },
    //     {
    //       label: t('Analytics V2'),
    //       href: 'https://app.mummy.finance/#/analytic-v2/overview',
    //       icon: AnalyticsV2Icon,
    //       describe: 'The comprehensive statistics and data visualization of V2',
    //     },
    //   ],
    // },
    {
      label: t('More'),
      href: '',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',
      items: [
        {
          icon: ReferralsIcon,
          label: t('Referrals'),
          href: 'https://app.mummy.finance/#/referrals',
          describe: 'Earn great rebates by referring your friends for trading',
        },
        {
          label: t('Analytics V1'),
          href: 'https://app.mummy.finance/#/analytic/overview',
          icon: AnalyticsV1Icon,
          describe: 'The comprehensive statistics and data visualization of V1',
        },
        {
          label: t('Analytics V2'),
          href: 'https://app.mummy.finance/#/analytic-v2/overview',
          icon: AnalyticsV2Icon,
          describe: 'The comprehensive statistics and data visualization of V2',
        },
        {
          label: t('LayerZero Bridge'),
          href: 'https://app.mummy.finance/#/bridge',
          icon: LayerZeroIcon,
          describe: 'Bridge MMY seamlessly via Layer0 technology',
        },
        {
          label: t('Wormhole Bridge'),
          href: 'https://app.mummy.finance/#/bridge-wormhole',
          icon: WormholeIcon,
          describe: 'Bridge tokens seamlessly via Wormhole technology',
        },
        // {
        //   icon: BridgesIcon,
        //   label: t('Bridge'),
        //   href: 'https://app.mummy.finance/#/bridge',
        //   describe: 'Bridge MMY seamlessly via Layer0 technology',
        // },
        // {
        //   icon: SkeletonIcon,
        //   label: t('Skeleton'),
        //   href: 'https://skeleton.finance/',
        //   describe: 'V3 DEX powered by Mummy',
        // },
        {
          icon: NewsIcon,
          label: t('News'),
          href: 'https://app.mummy.finance/#/news',
          describe: 'Stay updated with the latest crypto news',
        },
        {
          icon: PoLIcon,
          label: t('PoL'),
          href: 'https://app.mummy.finance/#/proof-of-locked-liquidity',
          describe: 'Protocol-Owned MMY Liquidity',
        },
        {
          icon: AboutIcon,
          label: t('About'),
          href: 'https://docs.mummy.finance/',
          describe: 'Mummy Finance Documentation',
        },
      ],
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
